import { EyeOutlined, RedoOutlined, MailOutlined } from "@ant-design/icons";
import { DeleteButton, EditButton, FilterDropdown, List, SaveButton, useModalForm, useSelect, useTable } from "@refinedev/antd";
import { useCreate, useMany } from "@refinedev/core";
import { Button, Col, Divider, Form, Input, Modal, Row, Select, Space, Table, Typography } from "antd";
import React from 'react';

const{ Title, Text } = Typography;

const to2 = num => num < 10 ? `0${ num }` : `${ num }`;

export const OpportunitiesList = () => {

    // const _existingStoredUser = localStorage.getItem('userIdentity');
    // let existingStoredUser;

    // if(_existingStoredUser){
    //     existingStoredUser = JSON.parse(_existingStoredUser);
    // }

    const { mutate } = useCreate();

    const [ currentOpportunity, setCurrentOpportunity ] = React.useState(null);
    const [ isOpporunityModalOpen, setIsOpporunityModalOpen ] = React.useState(false);
    const [ opportunityNameFilter, setOpportunityNameFilter ] = React.useState('');
    const [ opportunityTimeout, setOpportunityTimeout ] = React.useState(null);

	const { searchFormProps, setFilters, tableProps, tableQueryResult: { refetch } } = useTable({
        // onSearch: (values) => {
        //     return [
        //         {
        //             field: "state",
        //             operator: "equals",
        //             value: values.state,
        //         },
        //         {
        //             field: "ziporcounty",
        //             operator: "contains",
        //             value: values.ziporcounty,
        //         },
        //     ];
        // },
		resource: 'opportunities/appointments'
	});



    
    


    const displayOpportunity = (opportunity) => {

        setCurrentOpportunity(opportunity);
        setIsOpporunityModalOpen(true);

    }

    const hideOpportunity = () => {

        setCurrentOpportunity(null);
        setIsOpporunityModalOpen(false);

    }


    


    React.useEffect(() => {

        if(typeof opportunityTimeout === 'number'){
            clearTimeout(opportunityTimeout);
        }

        setOpportunityTimeout(setTimeout(() => {
        
            setFilters([
                {
                    field: "OpportunityNumber__c",
                    value: opportunityNameFilter,
                    operator: "endswith"
                }
            ]);

        }, 1500));


    }, [opportunityNameFilter]);


    function sendRequestToPersist(opportunity){

        mutate({
            resource: 'opportunities/requestSendMail',
            values: {
                ...opportunity
            },
            successNotification: (data, values, resource) => {
                return {
                    message: `Email will be sent shortly.`,
                    description: `Successfully requested`,
                    type: 'success'
                }
            }
        }, {
            onError: (error, variables, context) => {
                console.log(error);
                refetch();
            },
            onSuccess: (data, variables, context) => {
                setIsOpporunityModalOpen(false);
                setCurrentOpportunity(null);
                hideOpportunity();
                refetch();
            }
        })

    }


	return (

		<>

			<List title="Sundek appointments calendar">
                
				<Table { ...tableProps } rowKey="Id">

					<Table.Column 
                        dataIndex="OpportunityNumber__c" 
                        filtered
                        filterDropdown={(filterDropdownProps) => {
                            return (
                                <FilterDropdown
                                    {...filterDropdownProps}
                                    setSelectedKeys={() => undefined}
                                    clearFilters={() => setOpportunityNameFilter('')}
                                >
                                    
                                    <div>
                                        <Input
                                            maxLength={ 6 }
                                            onChange={ e => setOpportunityNameFilter(e.target.value?.replace(/\D/g,'')) }
                                            value={ opportunityNameFilter }
                                            
                                        />
                                    </div>

                                </FilterDropdown>
                            );
                        }}
                        title="Opportunity" 

                    />
                    <Table.Column dataIndex="Name" title="Opportunity name" />

                    <Table.Column dataIndex="Dealership_on_Account__c" title="Dealership"  />

                    <Table.Column dataIndex="Appointment_Date__c" title="Appointment date" render={ (t, r) => { if(!t) return null; let date = new Date(t); return `${ to2(date.getMonth() + 1)}/${ to2(date.getDate()) }/${ date.getFullYear() } ${ to2(date.getHours()) }:${ to2(date.getMinutes()) }` } } />

                    <Table.Column dataIndex="Owner" title="Owner" render={ (t, r) => t.Name } />

                    <Table.Column
                        title="Actions"
                        dataIndex="actions"

                        render={ (_text, record) => (

                            <>

                                <Space>
                                    <Button icon={ <EyeOutlined /> } onClick={ (e) => displayOpportunity(record) } />
                                </Space>
                                
                                <Space>
                                {
                                    !record.sendMailReq?.id &&
                                    <Button icon={ <MailOutlined /> } onClick={ (e) => sendRequestToPersist(record) } >Send email invite</Button>
                                }
                                </Space>
        
                                

                            </>

                        )}
                    />

                   
				</Table>

			</List>


            <Modal 
                okText="Send email"
                onCancel={ hideOpportunity }
                onOk={ e => sendRequestToPersist(currentOpportunity) }
                open={ isOpporunityModalOpen }
                title={ currentOpportunity ? <p style={{ borderBottom: 'solid 1px #666', fontSize: '1.1rem', paddingBottom: '0.5rem' }}><strong>Opportunity -</strong> { currentOpportunity.OpportunityNumber__c }</p> : 'Loading...' }
                width={ 700 }
            >

                {
                    currentOpportunity && isOpporunityModalOpen &&
                    <>
                        

                        <Row>
                            <Col span={ 12 }>
                                <Title level={5}>Owner</Title>
                                <Text>{ currentOpportunity.Owner.Name }</Text><br/>&nbsp;<br/>
                            </Col>
                            <Col span={ 12 }>
                                <Title level={5}>Owner email</Title>
                                <Text>{ currentOpportunity.Owner.Email }</Text><br/>&nbsp;<br/>
                            </Col>

                        </Row>

                        <Row>
                            <Col span={ 12 }>
                                <Title level={5}>Job type</Title>
                                <Text>{ currentOpportunity.JobType__r.Name }</Text><br/>&nbsp;<br/>
                            </Col>
                            <Col span={ 12 }>
                                <Title level={5}>Dealership</Title>
                                <Text>{ currentOpportunity.Dealership_on_Account__c }</Text><br/>&nbsp;<br/>
                            </Col>

                        </Row>

                        <Title level={ 5 }>Address</Title>
                        <Text>{ currentOpportunity.Account.Name }<br/>{ currentOpportunity.Account.BillingStreet }, { currentOpportunity.Account.BillingCity }, { currentOpportunity.Account.BillingState }, { currentOpportunity.Account.BillingPostalCode } </Text>

                    </>
                }
                

            </Modal>

		</>

	);

}