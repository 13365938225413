import { initializeApp } from "firebase/app";
import { collection, getDocs, getFirestore } from "firebase/firestore";


 var config_ = {

    apiKey: "AIzaSyDllK0svRArR78nsET6Sua2d2wtSR4Cp4o",
    authDomain: "sundek-9bc99.firebaseapp.com",
    projectId: "sundek-9bc99",
    storageBucket: "sundek-9bc99.appspot.com",
    messagingSenderId: "670129011756",
    appId: "1:670129011756:web:ad486c8f76c0912bd96589"

};


var app = initializeApp(config_);

 // const db = firebase.firestore();
 
 // var usrs={};

const db = getFirestore(app);


export const getUser = async (id) => {

    const querySnapshot = await getDocs(collection(db, 'users'));

    // console.log(querySnapshot);

    for(let i = 0; i < querySnapshot.docs.length; i++){

        let user = querySnapshot.docs[i].data();
        // console.log(user);

        if(user.uid === id){

            return user;

        }

    }

    return false;

}




export const verifyUser = async (uid) => {

    const user = await getUser(uid);
    const _existingStoredUser = localStorage.getItem('userIdentity');
    let existingStoredUser;

    if(_existingStoredUser){
        existingStoredUser = JSON.parse(_existingStoredUser);
    }


    if(!user) return localStorage.removeItem('userIdentity');

    if(existingStoredUser && existingStoredUser.uid === user.uid){

        return ;

    }

    localStorage.setItem('userIdentity', JSON.stringify(user));
    window.location.reload();

}