import { AccessControlProvider } from "@refinedev/core";

import { getUser } from './user';

export const accessControlProvider = {

  can: async ({ resource, action, params }) => {

    // console.log(resource); // products, orders, etc.
    // console.log(action); // list, edit, delete, etc.
    // console.log(params); // { id: 1 }, { id: 2 }, etc.


    let _user = localStorage.getItem('userIdentity'), user;
    if(_user){
      user = JSON.parse(_user);
    }


    if(!user){
      return {
        can: false,
        reason: "Unauthorized",
      };
    }


    if(params.resource?.meta?.parent === 'Management'){

      if(user.role === "administrator"){

        return { can: true }

      } else {
        return {
          can: false,
          reason: "Unauthorized",
        };
      }

    }

    return { can: true }
    
  },

};